import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { jobTypeMap } from '../pages/JobList';
import { keyframes } from '@mui/system';

function JobStatus({ jobs }) {
  if (!jobs || jobs.length === 0) {
    return <Typography variant="body2" color="text.secondary">Keine Analyse durchgeführt</Typography>;
  }

  const latestJob = jobs[0]; // Annahme: Jobs sind nach Datum sortiert
  const isActive = latestJob.status === 'pending' || latestJob.status === 'running';

  const pulse = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
    `;
  
  if (isActive) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <FiberManualRecordIcon 
          sx={{ 
            color: latestJob.status === 'pending' ? 'warning.main' : 'info.main',
            animation: `${pulse} 1.5s infinite`
          }} 
        />
        <Box>
          <Typography variant="body2">{jobTypeMap[latestJob.job_type]}</Typography>
          {latestJob.progress && (
            <Typography variant="caption" color="text.secondary">
              {`${latestJob.progress.current_step}/${latestJob.progress.total_steps} Schritte - ${latestJob.progress.step_description}`}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        
            <FiberManualRecordIcon 
                sx={{ 
                color: latestJob.status === 'completed' ? 'success.main' : 'error.main',
                }} 
            />
            <Box>
                {
                <Tooltip title={new Date(latestJob.updated_at).toLocaleString('de-DE')}>
                    <Typography variant="body2" color={latestJob.status === 'completed' ? 'success.main' : 'error.main'}>
                       {jobTypeMap[latestJob.job_type]} {latestJob.status === 'completed' ? 'abgeschlossen' : 'fehlgeschlagen'}
                    </Typography>
                </Tooltip>
                }
            </Box>
        
    </Box>
  );
}

export default JobStatus; 
import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { prepareRadarChartData } from '../utils/dashboardUtils';
import { FormControl, FormControlLabel, Switch, InputLabel, Select, MenuItem, Checkbox, ListItemText, Chip } from '@mui/material';
import { calculateAverageRatingByGender, calculateAverageRatingByCountry } from '../utils/dashboardUtils';

const COLORS = [
  '#36B37E', '#FF5630', '#00B8D9', '#6554C0', '#FFAB00',
  '#1F845A', '#E65100', '#0052CC', '#5243AA', '#FF8B00',
  '#006644', '#403294'
];

function DashboardCard({ title, children }) {
  return (
    <Paper elevation={0} sx={{ p: 2, height: '100%', borderRadius: '12px', border: '1px solid #e0e0e0', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {children}
    </Paper>
  );
}

const shortenName = (name, maxLength = 30) => {
  if (name.length <= maxLength) return name;
  return name.substr(0, maxLength - 3) + '...';
};

const prepareAttributeComparisonData = (mainProduct, competitors, isRelative) => {
  if (!mainProduct.reviews || mainProduct.reviews.length === 0) {
    return [];
  }

  const attributes = Object.keys(mainProduct.reviews[0].attributes_sentiment || {});

  return attributes.map(attribute => {
    const data = { attribute: attribute };
    
    const calculateValues = (reviews) => {
      const total = reviews.length;
      const positive = reviews.filter(r => r.attributes_sentiment && r.attributes_sentiment[attribute] && r.attributes_sentiment[attribute].sentiment === 1).length;
      const negative = reviews.filter(r => r.attributes_sentiment && r.attributes_sentiment[attribute] && r.attributes_sentiment[attribute].sentiment === -1).length;
      const neutral = total - positive - negative;
      return isRelative ? 
        { positive: positive / total, neutral: neutral / total, negative: negative / total } :
        { positive, neutral, negative };
    };

    const mainProductValues = calculateValues(mainProduct.reviews);
    data[`${mainProduct.name}_positive`] = mainProductValues.positive;
    data[`${mainProduct.name}_neutral`] = mainProductValues.neutral;
    data[`${mainProduct.name}_negative`] = mainProductValues.negative;

    competitors.forEach(comp => {
      if (comp.reviews && comp.reviews.length > 0) {
        const compValues = calculateValues(comp.reviews);
        data[`${comp.name}_positive`] = compValues.positive;
        data[`${comp.name}_neutral`] = compValues.neutral;
        data[`${comp.name}_negative`] = compValues.negative;
      }
    });

    return data;
  });
};

function ProductCompetitionDashboard({ product, competitors }) {
  const [productWithReviews, setProductWithReviews] = useState(null);
  const [competitorsWithReviews, setCompetitorsWithReviews] = useState([]);
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [isRelative, setIsRelative] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      if (product && competitors.length > 0) {
        const productReviews = await fetchReviewsForProduct(product.id);
        setProductWithReviews({ ...product, reviews: productReviews });

        const competitorsReviews = await Promise.all(
          competitors.map(async (comp) => {
            const reviews = await fetchReviewsForProduct(comp.id);
            return { ...comp, reviews };
          })
        );
        setCompetitorsWithReviews(competitorsReviews);
      }
    };

    fetchReviews();
    setSelectedCompetitors(competitors.length > 0 ? [competitors[0].id] : []);
  }, [product, competitors]);

  const fetchReviewsForProduct = async (productId) => {
    try {
      const response = await fetch(`/api/product/${productId}/reviews?get_attribute_sentiment_columns=true`);
      if (response.ok) {
        const data = await response.json();
        return data.reviews;
      }
      return [];
    } catch (error) {
      console.error('Fehler beim Laden der Reviews:', error);
      return [];
    }
  };

  const handleCompetitorSelection = (event) => {
    setSelectedCompetitors(event.target.value);
  };

  const prepareComparisonData = (calculateFunction) => {
    const mainProductData = calculateFunction(productWithReviews.reviews);
    const competitorsData = competitorsWithReviews
      .filter(comp => selectedCompetitors.includes(comp.id))
      .map(comp => calculateFunction(comp.reviews));
  
    const allCategories = [...new Set([
      ...mainProductData.map(item => item.gender || item.country),
      ...competitorsData.flatMap(compData => compData.map(item => item.gender || item.country))
    ])];
  
    return allCategories.map(category => {
      const comparisonItem = { category };
      
      const mainProductItem = mainProductData.find(item => (item.gender || item.country) === category);
      if (mainProductItem) {
        comparisonItem[productWithReviews.name] = mainProductItem.averageRating;
        comparisonItem[`${productWithReviews.name}_reviewCount`] = mainProductItem.reviewCount;
      }
  
      competitorsWithReviews
        .filter(comp => selectedCompetitors.includes(comp.id))
        .forEach((comp, index) => {
          const compData = competitorsData[index];
          const compItem = compData.find(item => (item.gender || item.country) === category);
          if (compItem) {
            comparisonItem[comp.name] = compItem.averageRating;
            comparisonItem[`${comp.name}_reviewCount`] = compItem.reviewCount;
          }
        });
  
      return comparisonItem;
    });
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
      {productWithReviews && competitorsWithReviews.length > 0 && (
        <>
          <Box sx={{ gridColumn: 'span 12', mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Wettbewerber auswählen</InputLabel>
              <Select
                multiple
                value={selectedCompetitors}
                onChange={handleCompetitorSelection}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={shortenName(competitors.find(competitor => competitor.id === value)?.name,30) || value} />
                    ))}
                  </Box>
                )}
              >
                {competitorsWithReviews.map((comp) => (
                  <MenuItem key={comp.id} value={comp.id}>
                    <Checkbox checked={selectedCompetitors.includes(comp.id)} />
                    <ListItemText primary={comp.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ gridColumn: 'span 6' }}>
            <DashboardCard title="Durchschnittliche Bewertung nach Geschlecht">
              <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={prepareComparisonData(calculateAverageRatingByGender)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis yAxisId="left" domain={[0, 5]} />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip 
                  formatter={(value, name, props) => {
                    const isReviewCount = name.includes('_reviewCount');
                    return [isReviewCount ? value : value.toFixed(2), shortenName(name.replace('_reviewCount', ''))];
                  }}
                  labelFormatter={(label) => `Geschlecht: ${label}`}
                />
                <Legend formatter={(value) => <span style={{ color: 'black' }}>{shortenName(value.replace('_reviewCount', ''))}</span>} />
                <Bar yAxisId="left" dataKey={productWithReviews.name} name={productWithReviews.name} fill={COLORS[0]} />
                {competitorsWithReviews
                  .filter(comp => selectedCompetitors.includes(comp.id))
                  .map((comp, index) => (
                    <Bar key={comp.id} yAxisId="left" dataKey={comp.name} name={comp.name} fill={COLORS[(index + 1) % COLORS.length]} />
                  ))
                }
                <Line yAxisId="right" type="monotone" dataKey={`${productWithReviews.name}_reviewCount`} stroke={COLORS[0]} strokeWidth={2} name={`${productWithReviews.name} Reviews`} />
                {competitorsWithReviews
                  .filter(comp => selectedCompetitors.includes(comp.id))
                  .map((comp, index) => (
                    <Line key={`${comp.id}_reviewCount`} yAxisId="right" type="monotone" dataKey={`${comp.name}_reviewCount`} stroke={COLORS[(index + 1) % COLORS.length]} strokeWidth={2} name={`${comp.name} Reviews`} />
                  ))
                }
              </ComposedChart>
              </ResponsiveContainer>
            </DashboardCard>
          </Box>
          <Box sx={{ gridColumn: 'span 6' }}>
            <DashboardCard title="Durchschnittliche Bewertung nach Land">
              <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={prepareComparisonData(calculateAverageRatingByCountry)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis yAxisId="left" domain={[0, 5]} />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip 
                  formatter={(value, name, props) => {
                    const isReviewCount = name.includes('_reviewCount');
                    return [isReviewCount ? value : value.toFixed(2), shortenName(name.replace('_reviewCount', ''))];
                  }}
                  labelFormatter={(label) => `Land: ${label}`}
                />
                <Legend formatter={(value) => <span style={{ color: 'black' }}>{shortenName(value.replace('_reviewCount', ''))}</span>} />
                <Bar yAxisId="left" dataKey={productWithReviews.name} name={productWithReviews.name} fill={COLORS[0]} />
                {competitorsWithReviews
                  .filter(comp => selectedCompetitors.includes(comp.id))
                  .map((comp, index) => (
                    <Bar key={comp.id} yAxisId="left" dataKey={comp.name} name={comp.name} fill={COLORS[(index + 1) % COLORS.length]} />
                  ))
                }
                {[productWithReviews, ...competitorsWithReviews.filter(comp => selectedCompetitors.includes(comp.id))].map((product, index) => (
                  <Line key={`${product.id}_reviewCount`} yAxisId="right" type="monotone" dataKey={`${product.name}_reviewCount`} stroke={COLORS[index % COLORS.length]} strokeWidth={2} name={`${product.name} Reviews`} />
                ))}
              </ComposedChart>
              </ResponsiveContainer>
            </DashboardCard>
          </Box>
          <Box sx={{ gridColumn: 'span 12' }}>
            <DashboardCard title="Attributvergleich (Radar Chart)">
              <ResponsiveContainer width="100%" height={800}>
                <RadarChart outerRadius={350} data={prepareRadarChartData(productWithReviews, competitorsWithReviews.filter(comp => selectedCompetitors.includes(comp.id)))}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="attribute" />
                  <PolarRadiusAxis angle={30} domain={[-1, 1]} />
                  <Radar name={shortenName(productWithReviews.name)} dataKey={productWithReviews.name} stroke={COLORS[0]} fill={COLORS[0]} fillOpacity={0.6} />
                  {competitorsWithReviews.filter(comp => selectedCompetitors.includes(comp.id)).map((comp, index) => (
                    <Radar key={comp.name} name={shortenName(comp.name)} dataKey={comp.name} stroke={COLORS[(index + 1) % COLORS.length]} fill={COLORS[(index + 1) % COLORS.length]} fillOpacity={0.6} />
                  ))}
                  <Legend formatter={(value) => <span style={{ color: 'black' }}>{value}</span>} />
                  <Tooltip 
                    contentStyle={{ color: 'black' }}
                    formatter={(value, name, props) => [`${name}: ${Number(value).toFixed(2)}`, props.payload.attribute]}
                    labelFormatter={(label) => `Attribut: ${label}`}
                  />
                </RadarChart>
              </ResponsiveContainer>
            </DashboardCard>
          </Box>
          <Box sx={{ gridColumn: 'span 12' }}>
          <DashboardCard>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Vergleich der Attributbewertungen</Typography>
            <FormControlLabel
              control={<Switch checked={isRelative} onChange={() => setIsRelative(!isRelative)} />}
              label="Relative Darstellung"
            />
          </Box>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={prepareAttributeComparisonData(productWithReviews, competitorsWithReviews.filter(comp => selectedCompetitors.includes(comp.id)), isRelative)}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="attribute" />
              <YAxis tickFormatter={isRelative ? (value) => `${(value * 100).toFixed(0)}%` : undefined} />
              <Tooltip formatter={(value) => isRelative ? `${(value * 100).toFixed(2)}%` : value} />
              <Legend />
              {[productWithReviews, ...competitorsWithReviews.filter(comp => selectedCompetitors.includes(comp.id))].map((product, index) => (
                <React.Fragment key={product.id}>
                  <Bar dataKey={`${product.name}_positive`} stackId={product.name} name={`${shortenName(product.name)} Positiv`} fill="#36B37E" />
                  <Bar dataKey={`${product.name}_neutral`} stackId={product.name} name={`${shortenName(product.name)} Neutral`} fill="#B3BAC5" />
                  <Bar dataKey={`${product.name}_negative`} stackId={product.name} name={`${shortenName(product.name)} Negativ`} fill="#FF5630" />
                </React.Fragment>
              ))}
            </BarChart>
          </ResponsiveContainer>
          </DashboardCard>
        </Box>
        </>
      )}
    </Box>
  );
}

export default ProductCompetitionDashboard;
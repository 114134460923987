import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Fab, IconButton, Button, TextField, InputAdornment } from '@mui/material';
import { Tabs, Tab } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ProductInfo from '../components/ProductInfo';
import ReviewTable from '../components/ReviewTable';
import useReviewTableConfig from '../services/useReviewTableConfig';
import ProductDetailDashboard from '../components/ProductDetailDashboard';
import ProductTile from "../components/ProductTile";
import CompetitorAnalysisForm from '../components/CompetitorAnalysisForm';
import ProductCompetitionDashboard from '../components/ProductCompetitionDashboard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isCompetitorDialogOpen, setIsCompetitorDialogOpen] = useState(false);
  const [competitorProducts, setCompetitorProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mainProduct, setMainProduct] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  const handleOpenCompetitorDialog = () => {
    setIsCompetitorDialogOpen(true);
  };

  const handleCloseCompetitorDialog = () => {
    setIsCompetitorDialogOpen(false);
  };

  const table = useReviewTableConfig(reviews);

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/api/product/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }

        const data = await response.json();
        setProduct(data.product);
        
        // Fetch reviews separately
        const reviewsResponse = await fetch(`/api/product/${id}/reviews?get_attribute_sentiment_columns=true`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (reviewsResponse.ok) {
          const reviewsData = await reviewsResponse.json();
          setReviews(reviewsData.reviews || []);
        } else {
          console.error('Fehler beim Laden der Reviews');
        }

        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Laden der Produktdaten:', error);
        setError('Fehler beim Laden der Produktdaten. Bitte versuchen Sie es später erneut.');
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchCompetitorProducts = useCallback(async () => {
    try {
      const response = await fetch(`/api/products?main_product_id=${id}&is_competitor=1`);
      if (!response.ok) {
        throw new Error('Fehler beim Laden der Wettbewerberprodukte');
      }
      const data = await response.json();
      const productsWithReviews = await Promise.all(data.products.map(async (product) => {
        const reviewsResponse = await fetch(`/api/product/${product.id}/reviews?get_attribute_sentiment_columns=true`);
        if (reviewsResponse.ok) {
          const reviewsData = await reviewsResponse.json();
          return { ...product, reviews: reviewsData.reviews || [] };
        }
        return product;
      }));
      setCompetitorProducts(productsWithReviews);
    } catch (error) {
      console.error('Fehler beim Laden der Wettbewerberprodukte:', error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const fetchMainProduct = useCallback(async () => {
    if (product && product.is_competitor && product.main_product_id) {
      try {
        const response = await fetch(`/api/product/${product.main_product_id}`);
        if (!response.ok) {
          throw new Error('Fehler beim Laden des Hauptprodukts');
        }
        const data = await response.json();
        setMainProduct(data.product);
      } catch (error) {
        console.error('Fehler beim Laden des Hauptprodukts:', error);
      }
    }
  }, [product]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCompetitorProducts();
      if (product) {
        await fetchMainProduct();
      }
    };
    fetchData();
  }, [id, product, fetchCompetitorProducts, fetchMainProduct]);

  const handleCompetitorAnalysisComplete = () => {
    fetchCompetitorProducts();
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const response = await fetch(`/api/product/${productId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Fehler beim Löschen des Produkts');
      }
      fetchCompetitorProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const NavigationButton = () => {
    const navigate = useNavigate();

    if (product.is_competitor && mainProduct) {
      return (
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/products/detail/${mainProduct.id}`)}
        >
          {`Zurück zu ${truncateText(mainProduct.name, 20)}`}
        </Button>
      );
    } else if (!product.is_competitor) {
      return (
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/products')}
        >
          Zurück zur Übersicht
        </Button>
      );
    }
    return null;
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (inputMessage.trim() === '') return;

    const newMessages = [...messages, { role: 'user', content: inputMessage }];
    setMessages(newMessages);
    setInputMessage('');
    setIsLoading(true);

    try {
      const response = await fetch(`/api/product/${id}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: newMessages }),
      });

      if (!response.ok) {
        throw new Error('Netzwerkantwort war nicht ok');
      }

      const data = await response.json();
      setMessages(data.messages);
    } catch (error) {
      console.error('Fehler beim Senden der Nachricht:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!product) {
    return <Typography>Produkt nicht gefunden.</Typography>;
  }

  const CompetitorProducts = ({ 
    competitorProducts, 
    isLoading, 
    handleOpenCompetitorDialog, 
    handleDeleteProduct, 
    isCompetitorDialogOpen, 
    handleCloseCompetitorDialog, 
    handleCompetitorAnalysisComplete, 
    productId 
  }) => {
    if (isLoading) return <CircularProgress />;

    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Wettbewerberprodukte</Typography>
          <Button variant="contained" onClick={handleOpenCompetitorDialog}>
            Wettbewerber ermitteln
          </Button>
        </Box>
        {competitorProducts.length === 0 ? (
          <Typography>Keine Wettbewerberprodukte gefunden.</Typography>
        ) : (
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 2 }}>
            {competitorProducts.map((product) => (
              <ProductTile key={product.id} product={product} onDelete={handleDeleteProduct} />
            ))}
          </Box>
        )}
        <CompetitorAnalysisForm
          productId={productId}
          onAnalysisComplete={handleCompetitorAnalysisComplete}
          open={isCompetitorDialogOpen}
          onClose={handleCloseCompetitorDialog}
        />
      </Box>
    );
  };

  const LoadingDots = () => {
    const loadingAnimation = keyframes`
      0% { content: '.'; }
      33% { content: '..'; }
      66% { content: '...'; }
    `;

    return (
      <Box
        sx={{
          '&::after': {
            content: '""',
            animation: `${loadingAnimation} 1s infinite`,
          },
        }}
      >
        Laden
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', maxWidth: '100%', overflow: 'hidden', flexDirection: 'column' }}>
      <Box sx={{ mb: 2 }}>
        <NavigationButton />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto', transition: 'margin-right 0.3s', marginRight: isChatOpen ? '300px' : '0' }}>
        <ProductInfo product={product} />
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Übersicht" />
            <Tab label="Reviews" />
            {!product.is_competitor && <Tab label="Wettbewerber" />}
            {!product.is_competitor && <Tab label="Wettbewerbsanalyse" />}
          </Tabs>
        </Box>
        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <ProductDetailDashboard reviews={reviews} productId={id} />
          )}
          {activeTab === 1 && reviews.length > 0 && (
            <ReviewTable table={table} reviews={reviews} />
          )}
          {!product.is_competitor && (
            <>
              {activeTab === 2 && (
                <CompetitorProducts 
                  competitorProducts={competitorProducts}
                  isLoading={isLoading}
                  handleOpenCompetitorDialog={handleOpenCompetitorDialog}
                  handleDeleteProduct={handleDeleteProduct}
                  isCompetitorDialogOpen={isCompetitorDialogOpen}
                  handleCloseCompetitorDialog={handleCloseCompetitorDialog}
                  handleCompetitorAnalysisComplete={handleCompetitorAnalysisComplete}
                  productId={id}
                />
              )}
              {activeTab === 3 && (
                <ProductCompetitionDashboard 
                  product={product} 
                  competitors={competitorProducts}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: '300px',
          height: '100vh',
          position: 'fixed',
          right: 0,
          top: 0,
          bgcolor: 'background.paper',
          borderLeft: '1px solid',
          borderColor: 'divider',
          transform: isChatOpen ? 'translateX(0)' : 'translateX(100%)',
          transition: 'transform 0.3s',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid', borderColor: 'divider', marginTop: '64px' }}>
          <Typography variant="h6">Chat</Typography>
          <IconButton onClick={() => setIsChatOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2, display: 'flex', flexDirection: 'column' }}>
          {messages.slice(3).map((message, index) => (
            <Box
              key={index}
              sx={{
                maxWidth: '70%',
                mb: 1,
                alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
                backgroundColor: message.role === 'user' ? 'primary.main' : 'grey.200',
                color: message.role === 'user' ? 'white' : 'text.primary',
                borderRadius: 2,
                p: 1,
              }}
            >
              <Typography variant="body2">{message.content}</Typography>
            </Box>
          ))}
          {isLoading && (
            <Box
              sx={{
                alignSelf: 'flex-start',
                backgroundColor: 'grey.200',
                color: 'text.primary',
                borderRadius: 2,
                p: 1,
                mt: 1,
              }}
            >
              <LoadingDots />
            </Box>
          )}
        </Box>
        <Box sx={{ p: 2, borderTop: '1px solid', borderColor: 'divider' }}>
          <form onSubmit={handleSendMessage}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Nachricht eingeben..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
      </Box>
      <Fab
        color="primary"
        aria-label="chat"
        onClick={() => setIsChatOpen(true)}
        sx={{ position: 'fixed', bottom: 16, right: 16, display: isChatOpen ? 'none' : 'flex' }}
      >
        <ChatIcon />
      </Fab>
    </Box>
  );
}

export default ProductDetail;
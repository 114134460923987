export const calculateTotalReviews = (reviews) => {
  return reviews.length;
};

export const calculateNewReviewsLast30Days = (reviews) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  return reviews.filter(review => new Date(review.date) >= thirtyDaysAgo).length;
};

export const calculateAverageRating = (reviews) => {
    const validRatings = reviews
      .map(review => parseFloat(review.rating))
      .filter(rating => !isNaN(rating));
  
    const sum = validRatings.reduce((acc, rating) => acc + rating, 0);
    return validRatings.length > 0 ? sum / validRatings.length : 0;
  };

export const calculateRatingChangeLast30Days = (reviews) => {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
  const recentReviews = reviews.filter(review => new Date(review.date) >= thirtyDaysAgo);

  const validRecentRatings = recentReviews
      .map(recentReviews => parseFloat(recentReviews.rating))
      .filter(rating => !isNaN(rating));

  const validReviews = reviews
      .map(reviews => parseFloat(reviews.rating))
      .filter(rating => !isNaN(rating));

  const recentAverage = validRecentRatings.length > 0 ? validRecentRatings.reduce((acc, rating) => acc + rating, 0) / validRecentRatings.length : 0;
  const olderAverage = validReviews.length > 0 ? validReviews.reduce((acc, rating) => acc + rating, 0) / validReviews.length : 0;
  return recentAverage - olderAverage;
};

export const calculateUniqueCountries = (reviews) => {
  const countries = new Set(reviews.map(review => review.country));
  return countries.size;
};

export const calculateAttributeSentiments = (reviews) => {
  const attributes = {};
  reviews.forEach(review => {
    if (review.attributes_sentiment) {
      Object.entries(review.attributes_sentiment).forEach(([attribute, data]) => {
        if (!attributes[attribute]) {
          attributes[attribute] = { positive: 0, negative: 0, notMentioned: 0, attribute_id: data.attribute_id };
        }
        if (data.sentiment === 1) {
          attributes[attribute].positive++;
        } else if (data.sentiment === -1) {
          attributes[attribute].negative++;
        } else {
          attributes[attribute].notMentioned++;
        }
      });
    }
  });
  return Object.entries(attributes).map(([name, sentiments]) => ({
    name,
    ...sentiments,
    total: sentiments.positive + sentiments.negative + sentiments.notMentioned
  }));
};

export const calculateAverageRatings = (reviews, interval = 'week') => {
  const ratings = {};
  
  reviews.forEach(review => {
    const date = new Date(review.date);
    let key;
    if (interval === 'week') {
      const weekStart = new Date(date.setDate(date.getDate() - date.getDay()));
      key = weekStart.toISOString().split('T')[0];
    } else if (interval === 'month') {
      key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    }
    
    if (!ratings[key]) {
      ratings[key] = { sum: 0, count: 0 };
    }
    
    ratings[key].sum += parseFloat(review.rating);
    ratings[key].count += 1;
  });
  
  return Object.entries(ratings)
    .map(([date, { sum, count }]) => ({
      date,
      averageRating: sum / count
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const calculateAverageRatingByGender = (reviews) => {
  const genderMap = {
    'female': 'Weiblich',
    'male': 'Männlich',
    'mostly_female': 'Überwiegend weiblich',
    'mostly_male': 'Überwiegend männlich',
    'unknown': 'Unbekannt'
  };

  const genderData = {};
  reviews.forEach(review => {
    const mappedGender = genderMap[review.author_gender] || 'Unbekannt';
    if (!genderData[mappedGender]) {
      genderData[mappedGender] = { sum: 0, count: 0 };
    }
    genderData[mappedGender].sum += parseFloat(review.rating);
    genderData[mappedGender].count += 1;
  });


  const result = Object.entries(genderData).map(([gender, data]) => ({
    gender,
    averageRating: data.sum / data.count,
    reviewCount: data.count
  })).sort((a, b) => b.reviewCount - a.reviewCount);
  return result
};

export const calculateAverageRatingByCountry = (reviews) => {
  const countryMap = {
    'DE': 'Deutschland',
    'US': 'USA',
    'UK': 'Vereinigtes Königreich',
    'IT': 'Italien',
    'ES': 'Spanien',
    'FR': 'Frankreich',
    'NL': 'Niederlande'
  };

  const countryData = {};
  reviews.forEach(review => {
    const mappedCountry = countryMap[review.country] || review.country;
    if (!countryData[mappedCountry]) {
      countryData[mappedCountry] = { sum: 0, count: 0 };
    }
    countryData[mappedCountry].sum += parseFloat(review.rating);
    countryData[mappedCountry].count += 1;
  });

  return Object.entries(countryData).map(([country, data]) => ({
    country,
    averageRating: data.sum / data.count,
    reviewCount: data.count
  })).sort((a, b) => b.reviewCount - a.reviewCount);
};

export const calculateRatingDistribution = (reviews, groupBy = null) => {
  const distribution = {1: {}, 2: {}, 3: {}, 4: {}, 5: {}};
  const groupValues = new Set();

  reviews.forEach(review => {
    const rating = Math.round(parseFloat(review.rating));
    if (rating >= 1 && rating <= 5) {
      const groupValue = groupBy ? (review[groupBy] || 'Unbekannt') : 'Gesamt';
      groupValues.add(groupValue);
      if (!distribution[rating][groupValue]) {
        distribution[rating][groupValue] = 0;
      }
      distribution[rating][groupValue]++;
    }
  });

  const result = Object.entries(distribution).map(([rating, groups]) => {
    const entry = { rating: parseInt(rating) };
    groupValues.forEach(groupValue => {
      entry[groupValue] = groups[groupValue] || 0;
    });
    entry.total = Object.values(groups).reduce((sum, count) => sum + count, 0);
    return entry;
  });
  return result;
};

export const calculateAverageSentimentByRating = (reviews) => {
  const sentimentByRating = {1: {}, 2: {}, 3: {}, 4: {}, 5: {}};
  const countByRating = {1: {}, 2: {}, 3: {}, 4: {}, 5: {}};
  const attributes = new Set();

  reviews.forEach(review => {
    const rating = Math.round(parseFloat(review.rating));
    if (rating >= 1 && rating <= 5 && review.attributes_sentiment) {
      Object.entries(review.attributes_sentiment).forEach(([attribute, sentiment]) => {
        attributes.add(attribute);
        if (!sentimentByRating[rating][attribute]) {
          sentimentByRating[rating][attribute] = 0;
          countByRating[rating][attribute] = 0;
        }
        countByRating[rating][attribute]++;
        if (sentiment.sentiment !== null) {
          sentimentByRating[rating][attribute] += sentiment.sentiment;
        }
      });
    }
  });

  const result = [];
  for (let rating = 1; rating <= 5; rating++) {
    const entry = { rating };
    attributes.forEach(attribute => {
      if (countByRating[rating][attribute] > 0) {
        entry[attribute] = sentimentByRating[rating][attribute] / countByRating[rating][attribute];
      } else {
        entry[attribute] = null;
      }
    });
    result.push(entry);
  }
  return result;
};

export const calculateMentionRateByRating = (reviews) => {
  const mentionsByRating = {1: {}, 2: {}, 3: {}, 4: {}, 5: {}};
  const totalByRating = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0};
  const attributes = new Set();

  reviews.forEach(review => {
    const rating = Math.round(parseFloat(review.rating));
    if (rating >= 1 && rating <= 5) {
      totalByRating[rating]++;
      if (review.attributes_sentiment) {
        Object.entries(review.attributes_sentiment).forEach(([attribute, data]) => {
          attributes.add(attribute);
          if (!mentionsByRating[rating][attribute]) {
            mentionsByRating[rating][attribute] = 0;
          }
          if (data.sentiment !== null && data.sentiment !== 0) {
            mentionsByRating[rating][attribute]++;
          }
        });
      }
    }
  });

  const result = [];
  for (let rating = 1; rating <= 5; rating++) {
    const entry = { rating };
    attributes.forEach(attribute => {
      entry[attribute] = totalByRating[rating] > 0 
        ? mentionsByRating[rating][attribute] / totalByRating[rating] 
        : 0;
    });
    result.push(entry);
  }

  console.log(result);
  return result;
};

export const prepareRadarChartData = (product, competitors) => {
  const attributes = new Set();
  product.reviews.forEach(review => {
    Object.keys(review.attributes_sentiment).forEach(attr => attributes.add(attr));
  });

  const productData = Array.from(attributes).map(attr => ({
    attribute: attr,
    [product.name]: calculateAverageSentiment(product.reviews, attr)
  }));

  competitors.forEach(comp => {
    productData.forEach(item => {
      item[comp.name] = calculateAverageSentiment(comp.reviews, item.attribute);
    });
  });

  return productData;
};

const calculateAverageSentiment = (reviews, attribute) => {
  const validReviews = reviews.filter(review => 
    review.attributes_sentiment && 
    review.attributes_sentiment[attribute] && 
    review.attributes_sentiment[attribute].sentiment !== null
  );
  
  if (validReviews.length === 0) return 0;
  
  const sum = validReviews.reduce((acc, review) => 
    acc + review.attributes_sentiment[attribute].sentiment, 0
  );
  
  return sum / validReviews.length;
};
import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography 
} from '@mui/material';

function DeleteConfirmDialog({ open, onClose, onConfirm, productName }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Produkt löschen</DialogTitle>
      <DialogContent>
        <Typography>
          Sind Sie sicher, dass Sie das Produkt "{productName}" löschen möchten?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={onConfirm} color="error" variant="contained">
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmDialog; 
import { useState, useMemo } from 'react';

export function useReviewTableConfig(reviews) {
  const [sorting, setSorting] = useState([]);
  const columns = useMemo(() => [
    {
      id: 'title',
      header: 'Titel',
      accessorKey: 'title'
    },
    {
      id: 'rating',
      header: 'Bewertung',
      accessorKey: 'rating'
    },
    {
      id: 'date',
      header: 'Datum',
      accessorKey: 'date'
    },
    {
      id: 'body',
      header: 'Text',
      accessorKey: 'body'
    },
    {
      id: 'author',
      header: 'Autor',
      accessorKey: 'author'
    },
    {
      id: 'country',
      header: 'Land',
      accessorKey: 'country'
    },
    {
      id: 'language',
      header: 'Sprache',
      accessorKey: 'language'
    },
    {
      id: 'author_gender',
      header: 'Author Gender',
      accessorKey: 'author_gender'
    },
    {
      id: 'flesch_reading_ease',
      header: 'Flesch Reading Ease',
      accessorKey: 'flesch_reading_ease'
    }
  ], []);

  return {
    columns,
    sorting,
    setSorting,
    getAllColumns: () => columns
  };
}

export default useReviewTableConfig;
import { handleResponse } from './utils';

export const ProductAPI = {
  getAll: async () => {
    const response = await fetch('/api/products');
    return handleResponse(response);
  },

  create: async (asin) => {
    const response = await fetch('/api/products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ asin, is_competitor: false }),
    });
    return handleResponse(response);
  },

  delete: async (productId) => {
    const response = await fetch(`/api/product/${productId}`, {
      method: 'DELETE',
    });
    return handleResponse(response);
  },
}; 
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon, Work as WorkIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Inventory } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ProductDetail from './pages/ProductDetail';
import ProductList from './pages/ProductList';
import JobList from './pages/JobList';
import logo from './logo.jpg';
import PasswordProtection from './components/PasswordProtection';

const drawerWidth = 240;

const theme = createTheme({
  palette: {
    primary: {
      main: '#1a1a1a',
    },
    secondary: {
      main: '#4CAF50',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: '#1a1a1a',
          boxShadow: 'none',
          borderBottom: '1px solid #e0e0e0',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f5f5f5',
          color: '#1a1a1a',
          borderRight: '1px solid #e0e0e0',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f5f5f5',
          color: '#1a1a1a',
          borderRadius:  0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #e0e0e0',
          borderRadius: '12px',
          maxWidth: '100%',
          overflow: 'scroll'
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PasswordProtection>
        <Router>
          <AppContent />
        </Router>
      </PasswordProtection>
    </ThemeProvider>
  );
}

function AppContent() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, borderRadius: 0 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Toggle sidebar"
            edge="start"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            sx={{ mr: 2 }}
          >
            {sidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Box 
            onClick={handleLogoClick} 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              cursor: 'pointer',
              flexGrow: 1
            }}
          >
            <img src={logo} alt="Logo" style={{ height: '32px', marginRight: '16px' }} />
            <Typography variant="h5" component="div">
              ProductLens AI
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: sidebarOpen ? drawerWidth : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            borderRight: 'none',
            transition: 'width 0.2s',
            overflowX: 'hidden',
            width: sidebarOpen ? drawerWidth : 0,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', mt: 2 }}>
          <List>
            <ListItem button component={Link} to="/products" selected={location.pathname === '/products'}>
              <ListItemIcon><Inventory /></ListItemIcon>
              <ListItemText primary="Produkte" />
            </ListItem>
            <ListItem button component={Link} to="/jobs" selected={location.pathname === '/jobs'}>
              <ListItemIcon><WorkIcon /></ListItemIcon>
              <ListItemText primary="Analyse-Jobs" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ 
          flexGrow: 1, 
          bgcolor: 'background.default', 
          p: 3,
          height: 'calc(100vh - 64px)', // Höhe abzüglich der AppBar-Höhe
          marginTop: '64px', // Platz für die AppBar
          overflowY: 'auto', // Ermöglicht Scrollen, wenn der Inhalt zu groß ist
          marginLeft: 0,
          transition: 'margin-left 0.2s',
        }}
      >
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/products/detail/:id" element={<ProductDetail />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/jobs" element={<JobList />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
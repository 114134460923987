import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField,
  CircularProgress,
  Alert
} from '@mui/material';
import { ProductAPI } from '../api/products';

function ProductCreateDialog({ open, onClose, onProductCreated }) {
  const [asin, setAsin] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await ProductAPI.create(asin);
      onProductCreated();
      onClose();
      setAsin('');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Neues Produkt hinzufügen</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <TextField
            autoFocus
            label="Amazon ASIN"
            fullWidth
            value={asin}
            onChange={(e) => setAsin(e.target.value)}
            disabled={loading}
            required
            helperText="Die ASIN finden Sie in der Amazon-URL oder in den Produktdetails"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Abbrechen
          </Button>
          <Button 
            type="submit" 
            variant="contained" 
            disabled={loading || !asin.trim()}
            endIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ProductCreateDialog; 
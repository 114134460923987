import React from 'react';
import { Box, Typography, Button, Paper, Grid } from '@mui/material';

function ProductInfo({ product }) {
  return (
    <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box sx={{ width: '300px', bgcolor: 'grey.200', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 1, margin: 'auto' }}>
            {product.image_url ? (
              <img src={product.image_url} alt={product.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              <Typography>Kein Bild verfügbar</Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>
            {product.name}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" component="span" sx={{ mr: 2 }}>
              ASIN: {product.asin}
            </Typography>
            <Typography variant="body1" component="span">
              Bereits gespeicherte Reviews: {product.review_count}
            </Typography>
          </Box>
          <Button variant="outlined"
              color="primary"
              href={`https://www.amazon.de/dp/${product.asin}`} target="_blank" rel="noopener noreferrer" sx={{ display: 'inline-block', mt: 2 }}>
            Produkt auf Amazon
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProductInfo;
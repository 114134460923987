import { handleResponse } from './utils';

export const JobAPI = {
  getAll: async () => {
    const response = await fetch('/api/jobs');
    return handleResponse(response);
  },

  create: async (productId, jobType) => {
    const response = await fetch('/api/jobs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_id: productId,
        job_type: jobType
      }),
    });
    if (!response.ok) throw new Error('Fehler beim Starten der Analyse');
    return response.json();
  },

  getByProductId: async (productId) => {
    const response = await fetch(`/api/jobs?product_id=${productId}`);
    if (!response.ok) {
      throw new Error('Fehler beim Laden der Jobs');
    }
    return response.json();
  }
}; 
import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  IconButton, 
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link } from 'react-router-dom';
import JobStatus from './JobStatus';

function ProductTile({ 
  product, 
  onDelete, 
  onStartFullAnalysis,
  jobs
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(product);
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ position: 'relative' }}>
        <IconButton 
          sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
          onClick={handleMenuOpen}
        >
          <MoreVertIcon />
        </IconButton>
        <Link to={`/products/detail/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
            {product.image_url ? (
              <CardMedia
                component="img"
                image={product.image_url}
                alt={product.name}
                sx={{ objectFit: 'contain', maxHeight: '100%', width: 'auto' }}
              />
            ) : (
              <ImageNotSupportedIcon sx={{ fontSize: 100, color: 'grey.500' }} />
            )}
          </Box>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h6" noWrap>{product.name}</Typography>
              <Typography color="textSecondary">ASIN: {product.asin}</Typography>
              <Typography>Reviews: {product.review_count || 0}</Typography>
              <JobStatus jobs={jobs} />
            </Box>
          </CardContent>
        </Link>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => { handleMenuClose(); onStartFullAnalysis(product.id); }}>
          <ListItemIcon><PlayArrowIcon /></ListItemIcon>
          <ListItemText>Volle Analyse durchführen</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon><DeleteOutlineIcon /></ListItemIcon>
          <ListItemText>Löschen</ListItemText>
        </MenuItem>
      </Menu>
    </Card>
  );
}

export default ProductTile;
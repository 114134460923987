import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  CircularProgress,
  Alert,
  Chip,
  Link
} from '@mui/material';
import { JobAPI } from '../api/jobs';
import { Link as RouterLink } from 'react-router-dom';

const getStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return 'warning';
    case 'running':
      return 'info';
    case 'completed':
      return 'success';
    case 'failed':
      return 'error';
    default:
      return 'default';
  }
};

const statusMap = {
  'pending': 'Wartend',
  'running': 'Läuft',
  'completed': 'Abgeschlossen',
  'failed': 'Fehlgeschlagen'
};

export const jobTypeMap = {
  'product_validation': 'Produkt validieren',
  'review_collection': 'Bewertungen sammeln',
  'review_analysis': 'Bewertungen analysieren',
  'full_analysis': 'Vollständige Analyse'
};

function JobList() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchJobs = async () => {
    try {
      const data = await JobAPI.getAll();
      setJobs(data.jobs);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
    const interval = setInterval(fetchJobs, 5000);
    return () => clearInterval(interval);
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Analyse-Jobs
      </Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Job ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Produkt ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>ASIN</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Produktname</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Typ</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Erstellt</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Aktualisiert</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.id}</TableCell>
                <TableCell>{job.product?.id}</TableCell>
                <TableCell>{job.product?.asin}</TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/products/detail/${job.product?.id}`}
                    sx={{ 
                      color: 'inherit',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    {job.product?.name}
                  </Link>
                </TableCell>
                <TableCell>{jobTypeMap[job.type]}</TableCell>
                <TableCell>
                  <Chip 
                    label={statusMap[job.status]} 
                    color={getStatusColor(job.status)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  {new Date(job.created_at).toLocaleString('de-DE')}
                </TableCell>
                <TableCell>
                  {new Date(job.updated_at).toLocaleString('de-DE')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default JobList; 
import React, { useState, useEffect } from 'react';
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Pagination, Paper, Button, TextField, Tooltip } from '@mui/material';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { CSVLink } from 'react-csv';
import { useReviewTableConfig } from '../services/useReviewTableConfig';

const countryMap = {
  'DE': '🇩🇪 Deutschland',
  'US': '🇺🇸 USA',
  'UK': '🇬🇧 Vereinigtes Königreich',
  'IT': '🇮🇹 Italien',
  'ES': '🇪🇸 Spanien',
  'FR': '🇫🇷 Frankreich',
  'NL': '🇳🇱 Niederlande'
};

const languageMap = {
  'de': 'Deutsch',
  'en': 'Englisch',
  'fr': 'Französisch',
  'it': 'Italienisch',
  'es': 'Spanisch',
  'nl': 'Niederländisch',
  'unknown': 'Unbekannt'
};

const genderMap = {
  'female': 'Weiblich',
  'male': 'Männlich',
  'unknown': 'Unbekannt'
};

const createAttributeColumns = (reviews) => {
  if (reviews.length === 0 || !reviews[0].attributes_sentiment) return [];
  
  const attributes = Object.keys(reviews[0].attributes_sentiment);
  return attributes.map(attribute => ({
    id: `attributes_sentiment.${attribute}`,
    header: attribute,
    accessorKey: `attributes_sentiment.${attribute}`,
    columnDef: {
      header: attribute
    }
  }));
};

function ReviewTable({ reviews }) {
  const [page, setPage] = useState(1);
  const [filteredReviews, setFilteredReviews] = useState(reviews);
  const [filters, setFilters] = useState({});
  const rowsPerPage = 20;
  
  const table = useReviewTableConfig(reviews);
  const [columns, setColumns] = useState(table.columns);

  useEffect(() => {
    setFilteredReviews(reviews);
  }, [reviews]);

  useEffect(() => {
    const tableColumns = table.getAllColumns();
    const attributeColumns = createAttributeColumns(reviews);
    const mergedColumns = [
      ...tableColumns,
      ...attributeColumns.map(col => ({
        ...col,
        columnDef: { header: col.header }
      }))
    ];
    setColumns(mergedColumns);
  }, [reviews, table]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (columnId, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [columnId]: value.toLowerCase()
    }));
  };

  useEffect(() => {
    const filtered = reviews.filter(review => {
      return Object.entries(filters).every(([columnId, filterValue]) => {
        const cellValue = review[columnId];
        return cellValue && cellValue.toString().toLowerCase().includes(filterValue);
      });
    });
    setFilteredReviews(filtered);
    setPage(1);
  }, [filters, reviews]);

  if (!reviews || reviews.length === 0) {
    return <Typography>Keine Reviews verfügbar.</Typography>;
  }

  const escapeCSV = (value) => {
    if (value == null) return '';
    value = value.toString().trim();
    value = value.replace(/"/g, '');
    value = value.replace(/\n/g, ' ');
    return `${value}`;
  };

  const createCSV = (data) => {
    const attributeColumns = createAttributeColumns(data);
    const header = [
      'Titel', 'Bewertung', 'Datum', 'Text', 'Autor', 'Land', 'Sprache', 
      'Sentiment Label', 'Sentiment Score', 'Flesch Reading Ease', 'Author Gender',
      ...attributeColumns.map(col => col.header)
    ];
    
    const rows = data.map(review => [
      review.title,
      review.rating,
      format(new Date(review.date), 'dd.MM.yyyy', { locale: de }),
      review.body,
      review.author,
      countryMap[review.country] || review.country,
      languageMap[review.language] || review.language,
      review.sentiment_label,
      review.sentiment_score,
      review.flesch_reading_ease,
      genderMap[review.author_gender] || review.author_gender,
      ...attributeColumns.map(col => {
        if (!review.attributes_sentiment[col.header]) return '';
        if (review.attributes_sentiment[col.header].sentiment === null) return '';
        const value = review.attributes_sentiment[col.header].sentiment;
        return value === null ? '' : value;
      })
    ]);

    const csvContent = [
      header.join(';'),
      ...rows.map(row => row.map(escapeCSV).join(';'))
    ].join('\n');

    return csvContent;
  };

  const csvData = createCSV(filteredReviews);

  return (
    <Box>
      <Box sx={{ gridColumn: 'span 12', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
      <Typography variant="h5">Alle Rezensionen</Typography>
      <CSVLink data={csvData} filename="reviews.csv" target="_blank">
          <Button variant="contained" color="primary">
            Als CSV herunterladen
          </Button>
        </CSVLink>
      </Box>
    <Paper sx={{ p: 3, mt: 4, maxWidth: '100%' }}>
      <Box sx={{ mb: 2 }}>
        
      </Box>
      <Box sx={{ p: 0, mt: 2, overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell 
                  key={column.id || column.accessorKey}
                  onClick={column.getToggleSortingHandler && column.getToggleSortingHandler()}
                  sx={{ 
                    fontWeight: 'bold', 
                    backgroundColor: '#f5f5f5',
                    whiteSpace: 'nowrap',
                    ...(column.id === 'body' && { minWidth: '300px' }),
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {column.columnDef && typeof column.columnDef.header === 'function'
                      ? column.columnDef.header()
                      : column.columnDef && column.columnDef.header
                      ? column.columnDef.header
                      : column.header || column.id || column.accessorKey}
                    {column.getIsSorted && (
                      <span>
                        {{
                          asc: '🔼',
                          desc: '🔽',
                        }[column.getIsSorted()] ?? '⇅'}
                      </span>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id || column.accessorKey}>
                  {['title', 'body', 'author'].includes(column.id) && (
                    <TextField
                      size="small"
                      placeholder="Suchen..."
                      onChange={(e) => handleFilterChange(column.id, e.target.value)}
                      fullWidth
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReviews.slice((page - 1) * rowsPerPage, page * rowsPerPage).map(review => (
              <TableRow key={review.id}>
                {columns.map(column => (
                  <TableCell key={column.id} sx={{ ...(column.id === 'body' && { minWidth: '300px' }) }}>
                    {column.id === 'date' 
                      ? format(new Date(review[column.accessorKey]), 'dd.MM.yyyy', { locale: de })
                      : column.id === 'country'
                      ? countryMap[review[column.accessorKey]] || review[column.accessorKey]
                      : column.id === 'language'
                      ? languageMap[review[column.accessorKey]] || review[column.accessorKey]
                      : column.id === 'author_gender'
                      ? genderMap[review[column.accessorKey]] || review[column.accessorKey]
                      : column.id === 'rating' || column.id === 'flesch_reading_ease'
                      ? Number(review[column.accessorKey]).toFixed(2)
                      : column.id === 'body'
                      ? <Tooltip title={review[column.accessorKey]} arrow>
                          <Typography
                            sx={{
                              display: '-webkit-box',
                              WebkitLineClamp: 5,
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {review[column.accessorKey]}
                          </Typography>
                        </Tooltip>
                      : column.id.startsWith('attributes_sentiment')
                      ? review.attributes_sentiment[column.header]?.sentiment === 1 
                        ? 'Positiv'
                        : review.attributes_sentiment[column.header]?.sentiment === -1
                        ? 'Negativ'
                        : 'Neutral'
                      : review[column.accessorKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil((filteredReviews.length || 0) / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </Box>
    </Paper>
    </Box>
  );
}

export default ReviewTable;
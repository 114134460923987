import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  IconButton,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Container,
  Link
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ProductAPI } from '../api/products';
import { JobAPI } from '../api/jobs';
import ProductCreateDialog from '../components/ProductCreateDialog';
import ProductTile from '../components/ProductTile';
import { Link as RouterLink } from 'react-router-dom';
import DeleteConfirmDialog from '../components/DeleteConfirmDialog';
import JobStatus from '../components/JobStatus';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [viewMode, setViewMode] = useState('table'); // 'table' oder 'grid'
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [productJobs, setProductJobs] = useState({});  // Neuer State für Jobs
  const [previousJobStates, setPreviousJobStates] = useState({});

  const fetchProducts = async () => {
    try {
      const data = await ProductAPI.getAll();
      setProducts(data.products);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobs = useCallback(async () => {
    try {
      const jobPromises = products.map(product => JobAPI.getByProductId(product.id));
      const jobResults = await Promise.all(jobPromises);
      const jobMap = {};
      jobResults.forEach((result, index) => {
        jobMap[products[index].id] = result.jobs;
      });
      setProductJobs(jobMap);
    } catch (error) {
      console.error('Fehler beim Laden der Jobs:', error);
    }
  }, [products]);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      fetchJobs();
      const interval = setInterval(fetchJobs, 5000);
      return () => clearInterval(interval);
    }
  }, [products, fetchJobs]);

  const compareJobStates = useCallback((currentJobs, previousJobs) => {
    if (!currentJobs || currentJobs.length === 0) return false;
    if (!previousJobs || previousJobs.length === 0) return false;

    const currentJob = currentJobs[0];
    const previousJob = previousJobs[0];

    // Prüfe ob sich der Fortschritt geändert hat
    const hasProgressChanged = 
      currentJob.status === 'running' &&
      previousJob.status === 'running' &&
      currentJob.progress?.current_step !== previousJob.progress?.current_step;

    // Prüfe ob der Job abgeschlossen wurde
    const hasCompleted = 
      previousJob.status === 'running' &&
      (currentJob.status === 'completed' || currentJob.status === 'failed');

    return hasProgressChanged || hasCompleted;
  }, []);

  useEffect(() => {
    let shouldFetchProducts = false;
    
    Object.entries(productJobs).forEach(([productId, jobs]) => {
      if (jobs && jobs.length > 0 && previousJobStates[productId]) {
        if (compareJobStates(jobs, previousJobStates[productId])) {
          shouldFetchProducts = true;
        }
      }
    });

    if (shouldFetchProducts) {
      fetchProducts();
    }
    
    setPreviousJobStates(productJobs);
  }, [productJobs, previousJobStates, compareJobStates]);

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await ProductAPI.delete(productToDelete.id);
      setDeleteDialogOpen(false);
      setProductToDelete(null);
      fetchProducts();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleStartAnalysis = async (productId) => {
    try {
      await JobAPI.create(productId, 'product_validation');
      setSnackbar({
        open: true,
        message: 'Analyse wurde gestartet',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error'
      });
    }
  };

  const handleFullAnalysis = async (productId) => {
    try {
      await JobAPI.create(productId, 'full_analysis');
      setSnackbar({
        open: true,
        message: 'Produktanalyse wurde gestartet',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error'
      });
    }
  };


  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Produkte</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="Ansichtsmodus"
          >
            <ToggleButton value="table" aria-label="Tabelle">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="Kacheln">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setIsCreateDialogOpen(true)}
          >
            Produkt hinzufügen
          </Button>
        </Box>
      </Box>
      
      {viewMode === 'table' ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Bild</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>ASIN</TableCell>
                <TableCell>Reviews</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Analyse</TableCell>
                <TableCell align="right">Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => {
                const jobs = productJobs[product.id] || [];
                const activeJob = jobs.length > 0 && 
                  (jobs[0].status === 'pending' || jobs[0].status === 'running');
                
                return (
                  <TableRow key={product.id}>
                    <TableCell>{product.id}</TableCell>
                    <TableCell>
                      <Box sx={{ width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {product.image_url ? (
                          <img 
                            src={product.image_url} 
                            alt={product.name}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                          />
                        ) : (
                          <ImageNotSupportedIcon color="disabled" />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Link 
                        to={`/products/detail/${product.id}`} 
                        style={{ 
                          textDecoration: 'none', 
                          color: 'inherit', 
                          fontWeight: 'bold',
                          '&:hover': { 
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }
                        }}
                        component={RouterLink}
                      >
                        {product.name}
                      </Link>
                    </TableCell>
                    <TableCell>{product.asin}</TableCell>
                    <TableCell>{product.review_count}</TableCell>
                    <TableCell>
                      <JobStatus jobs={productJobs[product.id]} />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton 
                        href={`https://www.amazon.de/dp/${product.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Auf Amazon öffnen"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleFullAnalysis(product.id)} 
                        title="Produkt analysieren"
                        disabled={!!activeJob}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleStartAnalysis(product.id)} 
                        title="Validate Product"
                        disabled={!!activeJob}
                      >
                        <VerifiedIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleDeleteClick(product)} 
                        title="Produkt löschen"
                        disabled={!!activeJob}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            {products?.map((product) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                <ProductTile 
                  product={product}
                  onDelete={(product) => handleDeleteClick(product)}
                  onStartFullAnalysis={handleFullAnalysis}
                  jobs={productJobs[product.id]} 
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
      
      <ProductCreateDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onProductCreated={fetchProducts}
      />
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        productName={productToDelete?.name}
      />
    </Box>
  );
}

export default ProductList;
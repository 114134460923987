import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, ResponsiveContainer, ComposedChart } from 'recharts';
import { Box, Typography, Paper, Popover, CircularProgress, ToggleButtonGroup, ToggleButton, Select, MenuItem, FormControl, InputLabel, Switch, FormControlLabel, Button, Collapse } from '@mui/material';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect } from 'react';

const COLORS = [
  '#36B37E', // Grün
  '#FF5630', // Rot
  '#00B8D9', // Hellblau
  '#6554C0', // Lila
  '#FFAB00', // Orange
  '#1F845A', // Dunkelgrün
  '#E65100', // Dunkelorange
  '#0052CC', // Dunkelblau
  '#5243AA', // Dunkellila
  '#FF8B00', // Hellorange
  '#006644', // Sehr dunkelgrün
  '#403294'  // Sehr dunkellila
];

function DashboardCard({ title, children }) {
  return (
    <Paper elevation={0} sx={{ p: 2, height: '100%', borderRadius: '12px', border: '1px solid #e0e0e0', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {children}
    </Paper>
  );
}

function ProductDetailDashboard({ productId }) {
  const [analysisData, setAnalysisData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAttribute, setOpenAttribute] = useState(null);
  const [quotes, setQuotes] = useState([]);
  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const [interval, setInterval] = useState('month');
  const [groupBy, setGroupBy] = useState('none');
  const [isRelative, setIsRelative] = useState(false);
  const [interpretation, setInterpretation] = useState('');
  const [isInterpretationOpen, setIsInterpretationOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState('average');

  useEffect(() => {
    const fetchAnalysisData = async () => {
      try {
        const response = await axios.get(`/api/product/${productId}/analysis`);
        setAnalysisData(response.data);
      } catch (error) {
        console.error('Fehler beim Laden der Analysedaten:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysisData();
  }, [productId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!analysisData) {
    return <Typography>Keine Daten verfügbar</Typography>;
  }

  const {
    total_reviews,
    new_reviews_last_30_days,
    average_rating,
    rating_change_last_30_days,
    unique_countries,
    attribute_sentiments,
    average_ratings_over_time,
    rating_distribution,
    average_sentiment_by_rating,
    mention_rate_by_rating
  } = analysisData;

  const handleClick = async (event, attribute, sentiment) => {
    setAnchorEl(event.currentTarget);
    setOpenAttribute(attribute);
    setLoadingQuotes(true);
    setQuotes([{ sentiment: sentiment.toString() }]); // Setze das Sentiment sofort
    try {
      const attributeData = attribute_sentiments.find(a => a.name === attribute);
      if (!attributeData) {
        throw new Error('Attribut nicht gefunden');
      }
      const response = await fetch(`/api/attribute/${attributeData.attribute_id}/quotes?sentiment=${sentiment}`);
      if (!response.ok) {
        throw new Error('Fehler beim Laden der Zitate');
      }
      const data = await response.json();
      setQuotes(data.quotes.map(quote => ({ ...quote, sentiment: sentiment.toString() })));
    } catch (error) {
      console.error('Fehler beim Laden der Zitate:', error);
      setQuotes([{ sentiment: sentiment.toString() }]); // Behalte das Sentiment bei einem Fehler
    } finally {
      setLoadingQuotes(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenAttribute(null);
    setQuotes([]);
  };

  const fetchInterpretation = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/api/dashboard/chatbot/interprete-avg-sentiment-by-rating', {
        productInformation: {
            avgSentimentbyRating: average_sentiment_by_rating,
            mentionRatebyRating: mention_rate_by_rating
        }
      });
      setInterpretation(response.data.interpretation);
      setIsInterpretationOpen(true);
    } catch (error) {
      console.error('Fehler beim Abrufen der Interpretation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportToPDF = () => {
    const input = document.getElementById('dashboard-content');
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [canvas.width, canvas.height],
        compress: true
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
      pdf.save("dashboard.pdf");
    });
  };

  const open = Boolean(anchorEl);

  return (
    <Box id="dashboard-content" sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
      <Box sx={{ gridColumn: 'span 12', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5">Produktdetails Dashboard</Typography>
        <Button variant="contained" onClick={exportToPDF}>
          Als PDF exportieren
        </Button>
      </Box>
      <Box sx={{ gridColumn: 'span 4' }}>
        <DashboardCard title="Gesamtanzahl der Reviews">
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h4" align="center">{total_reviews}</Typography>
            <Typography variant="body2" color="success.main" align="center">+{new_reviews_last_30_days} in den letzten 30 Tagen</Typography>
          </Box>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 4' }}>
        <DashboardCard title="Durchschnittliches Bewertung">
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h4" align="center">{average_rating.toFixed(2)}</Typography>
            <Typography variant="body2" color={rating_change_last_30_days >= 0 ? "success.main" : "error.main"} align="center">
              {rating_change_last_30_days >= 0 ? '+' : ''}{rating_change_last_30_days.toFixed(2)} in den letzten 30 Tagen
            </Typography>
          </Box>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 4' }}>
        <DashboardCard title="Anzahl der Länder">
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h4" align="center">{unique_countries}</Typography>
            <Typography variant="body2" align="center">Betrachtete Länder</Typography>
          </Box>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 12' }}>
        <DashboardCard title="Einschätzung der Attribute">
          <Box sx={{ width: '100%', overflowX: 'auto' }}>
            {attribute_sentiments.map((attribute) => (
              <Box key={attribute.name} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">{attribute.name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', height: 20, width: '100%' }}>
                  <Box 
                    sx={{ 
                      width: `${(attribute.positive / attribute.total) * 100}%`, 
                      height: '100%', 
                      bgcolor: COLORS[0],
                      cursor: 'pointer'
                    }} 
                    onClick={(e) => handleClick(e, attribute.name, 1)}
                  />
                  <Box sx={{ width: `${(attribute.notMentioned / attribute.total) * 100}%`, height: '100%', bgcolor: '#E0E0E0' }} />
                  <Box 
                    sx={{ 
                      width: `${(attribute.negative / attribute.total) * 100}%`, 
                      height: '100%', 
                      bgcolor: COLORS[1],
                      cursor: 'pointer'
                    }} 
                    onClick={(e) => handleClick(e, attribute.name, -1)}
                  />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">Positiv: {attribute.positive}</Typography>
                  <Typography variant="caption">Nicht erwähnt: {attribute.notMentioned}</Typography>
                  <Typography variant="caption">Negativ: {attribute.negative}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 12' }}>
        <DashboardCard>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Zeitliche Entwicklung der Bewertungen</Typography>
            <ToggleButtonGroup
              value={interval}
              exclusive
              onChange={(event, newInterval) => {
                if (newInterval !== null) {
                  setInterval(newInterval);
                }
              }}
              aria-label="Zeitintervall"
              size="small"
            >
              <ToggleButton value="week" aria-label="wöchentlich">
                Woche
              </ToggleButton>
              <ToggleButton value="month" aria-label="monatlich">
                Monat
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={average_ratings_over_time}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(tick) => {
                  const date = new Date(tick);
                  return interval === 'week'
                    ? date.toLocaleDateString('de-DE', { month: 'short', day: 'numeric' })
                    : date.toLocaleDateString('de-DE', { month: 'short', year: 'numeric' });
                }}
              />
              <YAxis domain={[1, 5]} />
              <Tooltip 
                labelFormatter={(label) => new Date(label).toLocaleDateString('de-DE', { day: 'numeric', month: 'long', year: 'numeric' })}
                formatter={(value) => [value.toFixed(2), "Durchschnittliche Bewertung"]}
              />
              <Legend formatter={(value) => <span style={{ color: 'black' }}>{value}</span>} />
              <Line type="monotone" dataKey="averageRating" name="Durchschnittliche Bewertung" strokeWidth={2} dot={false} stroke={COLORS[0]} />
            </LineChart>
          </ResponsiveContainer>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 6' }}>
        <DashboardCard>
            <Typography variant="h6" gutterBottom>Durchschnittliche Bewertung nach Geschlecht</Typography>
            <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={analysisData.average_rating_by_gender}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="gender" />
                <YAxis yAxisId="left" domain={[0, 5]} />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip />
                <Legend formatter={(value) => <span style={{ color: 'black' }}>{value}</span>} />
                <Bar yAxisId="left" dataKey="averageRating" name="Durchschnittliche Bewertung" fill={COLORS[0]} />
                <Line yAxisId="right" type="monotone" dataKey="reviewCount" stroke={COLORS[1]} strokeWidth={2} name="Anzahl der Reviews" />
            </ComposedChart>
            </ResponsiveContainer>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 6' }}>
        <DashboardCard>
          <Typography variant="h6" gutterBottom>Durchschnittliche Bewertung nach Land</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={analysisData.average_rating_by_country}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="country" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend formatter={(value) => <span style={{ color: 'black' }}>{value}</span>} />
              <Bar yAxisId="left" dataKey="averageRating" name="Durchschnittliche Bewertung" fill={COLORS[0]} />
              <Line yAxisId="right" type="monotone" dataKey="reviewCount" stroke={COLORS[1]} strokeWidth={2} name="Anzahl der Reviews" />
            </ComposedChart>
          </ResponsiveContainer>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 12' }}>
        <DashboardCard>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Verteilung der Bewertungen</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 120, mr: 2 }}>
                <InputLabel>Gruppierung</InputLabel>
                <Select
                  value={groupBy}
                  onChange={(e) => setGroupBy(e.target.value)}
                  label="Gruppierung"
                >
                  <MenuItem value="none">Keine</MenuItem>
                  <MenuItem value="country">Land</MenuItem>
                  <MenuItem value="language">Sprache</MenuItem>
                  <MenuItem value="gender">Geschlecht</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={<Switch checked={isRelative} onChange={(e) => setIsRelative(e.target.checked)} />}
                label="Relativ"
              />
            </Box>
          </Box>
          <ResponsiveContainer width="100%" height={400}>
            {rating_distribution && rating_distribution[groupBy] && rating_distribution[groupBy].length > 0 ? (
              <BarChart
                data={rating_distribution[groupBy]}
                stackOffset={isRelative ? "expand" : "none"}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="rating" />
                <YAxis tickFormatter={isRelative ? (value) => `${(value * 100).toFixed(0)}%` : undefined} />
                <Tooltip
                  formatter={(value, name, props) => {
                    const formattedValue = isRelative
                      ? `${((value / props.payload.total) * 100).toFixed(2)}%`
                      : value;
                    return [formattedValue, name === 'total' ? 'Gesamt' : name];
                  }}
                />
                <Legend formatter={(value) => <span style={{ color: 'black' }}>{value}</span>} />
                {Object.keys(rating_distribution[groupBy][0])
                  .filter(key => key !== 'rating')
                  .map((key, index) => (
                    <Bar key={key} dataKey={key} stackId="a" name={key} fill={COLORS[index % COLORS.length]} />
                  ))
                }
              </BarChart>
            ) : (
              <Typography>Keine Daten verfügbar</Typography>
            )}
          </ResponsiveContainer>
        </DashboardCard>
      </Box>
      <Box sx={{ gridColumn: 'span 12' }}>
        <DashboardCard>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Sentimentwerte und Erwähnungen pro Attribut nach Rating</Typography>
            <Box>
              <ToggleButtonGroup
                value={displayMode}
                exclusive
                onChange={(event, newMode) => {
                  if (newMode !== null) {
                    setDisplayMode(newMode);
                  }
                }}
                aria-label="Anzeigemodus"
                size="small"
                sx={{ mr: 2 }}
              >
                <ToggleButton value="average" aria-label="Durchschnitt">
                  Durchschnitt
                </ToggleButton>
                <ToggleButton value="mentionRate" aria-label="Anteil Nennungen">
                  Anteil Nennungen
                </ToggleButton>
              </ToggleButtonGroup>
              <Button onClick={fetchInterpretation} disabled={isLoading}>
                {isLoading ? 'Lädt...' : 'Was bedeutet das?'}
              </Button>
            </Box>
          </Box>
          <Collapse in={isInterpretationOpen}>
            <Box sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <ReactMarkdown>{interpretation}</ReactMarkdown>
            </Box>
          </Collapse>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={displayMode === 'average' ? average_sentiment_by_rating : mention_rate_by_rating}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="rating" type="number" domain={[1, 5]} ticks={[1, 2, 3, 4, 5]} />
              <YAxis domain={displayMode === 'average' ? [-1, 1] : [0, 1]} tickFormatter={displayMode === 'mentionRate' ? (value) => `${(value * 100).toFixed(0)}%` : undefined} />
              <Tooltip formatter={(value) => displayMode === 'mentionRate' ? `${(value * 100).toFixed(2)}%` : value.toFixed(2)} />
              <Legend formatter={(value) => <span style={{ color: 'black' }}>{value}</span>} />
              {average_sentiment_by_rating && average_sentiment_by_rating.length > 0 && mention_rate_by_rating && mention_rate_by_rating.length > 0 && 
                Object.keys(displayMode === 'average' ? average_sentiment_by_rating[0] : mention_rate_by_rating[0])
                  .filter(key => key !== 'rating')
                  .map((attribute, index) => (
                    <Line
                      key={attribute}
                      type="monotone"
                      dataKey={attribute}
                      stroke={COLORS[index % COLORS.length]}
                      strokeWidth={2}
                      dot={false}
                      connectNulls
                    />
                  ))
              }
            </LineChart>
          </ResponsiveContainer>
        </DashboardCard>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 300 }}>
          <Typography variant="h6" gutterBottom>
            {openAttribute} - {quotes.length > 0 && quotes[0].sentiment === '1' ? 'Positive' : 'Negative'} Zitate
          </Typography>
          {loadingQuotes ? (
            <CircularProgress />
          ) : (
            quotes.map((quote, index) => (
              <Typography key={index} variant="body2" gutterBottom>
                {quote.text ? `"${quote.text}"` : 'Lade Zitate...'}
              </Typography>
            ))
          )}
        </Box>
      </Popover>
    </Box>
  );
}

export default ProductDetailDashboard;
import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Switch, TextField, FormControlLabel, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Chip } from '@mui/material';

const countryOptions = [
  { value: 'AE', label: 'Vereinigte Arabische Emirate' },
  { value: 'AU', label: 'Australien' },
  { value: 'BR', label: 'Brasilien' },
  { value: 'CA', label: 'Kanada' },
  { value: 'DE', label: 'Deutschland' },
  { value: 'EG', label: 'Ägypten' },
  { value: 'ES', label: 'Spanien' },
  { value: 'FR', label: 'Frankreich' },
  { value: 'IN', label: 'Indien' },
  { value: 'IT', label: 'Italien' },
  { value: 'JP', label: 'Japan' },
  { value: 'MX', label: 'Mexiko' },
  { value: 'NL', label: 'Niederlande' },
  { value: 'PL', label: 'Polen' },
  { value: 'SA', label: 'Saudi-Arabien' },
  { value: 'SE', label: 'Schweden' },
  { value: 'SG', label: 'Singapur' },
  { value: 'TR', label: 'Türkei' },
  { value: 'UK', label: 'Vereinigtes Königreich' },
  { value: 'US', label: 'USA' }
];

const CompetitorAnalysisForm = ({ productId, onAnalysisComplete, open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    competitorCount: 5,
    maxPages: 1,
    countries: ['DE'],
    performAttributeAnalysis: false
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSwitchChange = (event) => {
    setFormValues(prevValues => ({
      ...prevValues,
      performAttributeAnalysis: event.target.checked
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`/api/product/${productId}/competitor-analysis`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues),
      });
      if (!response.ok) throw new Error('Netzwerkantwort war nicht ok');
      onAnalysisComplete();
      onClose();
    } catch (error) {
      console.error('Fehler bei der Wettbewerbsanalyse:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Wettbewerberanalyse</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={onSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            name="competitorCount"
            label="Anzahl Wettbewerbsprodukte"
            type="number"
            value={formValues.competitorCount}
            onChange={handleChange}
            InputProps={{ inputProps: { min: 1, max: 20 } }}
            margin="normal"
          />
          <TextField
            fullWidth
            name="maxPages"
            label="Anzahl Seiten für Review (pro Produkt)"
            type="number"
            value={formValues.maxPages}
            onChange={handleChange}
            InputProps={{ inputProps: { min: 1, max: 10 } }}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Amazon Plattformen</InputLabel>
            <Select
              multiple
              name="countries"
              value={formValues.countries}
              onChange={handleChange}
              label="Amazon Plattformen"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={countryOptions.find(option => option.value === value)?.label || value} />
                  ))}
                </Box>
              )}
            >
              {countryOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={formValues.performAttributeAnalysis}
                onChange={handleSwitchChange}
                name="performAttributeAnalysis"
              />
            }
            label="Direkt Attribute-Analyse für alle durchführen"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button type="submit" variant="contained" onClick={onSubmit} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Wettbewerber ermitteln'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompetitorAnalysisForm;